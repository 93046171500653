import { Injectable } from '@angular/core';
import variant from '@jitl/quickjs-singlefile-browser-release-asyncify';
import { newQuickJSAsyncWASMModuleFromVariant } from 'quickjs-emscripten-core';
import { Chunk } from '../../interfaces/chunk/chunk.interface';
import { GeneralHelpers } from '../../helpers/general.helper';
import { ProjectVariablesService } from '../project-variables/project-variables.service';
import { NotebookVariablesService } from '../notebook-variables/notebook-variables.service';
import { TruncationHelper } from '../../helpers/truncation.helper';

export type JsRunner = {
  runtime: any;
  context: any;
  chunk: Chunk;
};

@Injectable({
  providedIn: 'root',
})
export class JavascriptRunnerService {
  public quickJs!: any;
  public runtime: any;
  private runners: Map<number, JsRunner> = new Map();

  constructor(
    private projectVariablesService: ProjectVariablesService,
    private notebookVariablesService: NotebookVariablesService
  ) {}

  public async init() {
    this.quickJs = await newQuickJSAsyncWASMModuleFromVariant(variant);
    await this.startRuntime();
  }

  public async startRuntime() {
    this.runtime = this.quickJs.newRuntime();
    this.runtime.setMemoryLimit(5e7);
    this.runtime.setMaxStackSize(5e6);
    this.runtime.setInterruptHandler(this.createInterruptHandler());
  }

  private createInterruptHandler() {
    let interruptCycles = 0;
    return () => ++interruptCycles > 1024;
  }

  public createContext() {
    const context = this.runtime.newContext();
    return context;
  }

  public disposeRuntime() {
    this.runtime?.dispose();
    this.runtime = null;
  }

  public async evalCodeAsync(context: any, content: string) {
    if (!context.alive) {
      throw new Error('Context is not alive');
    }
    return await context.evalCodeAsync(content);
  }

  public createRunner(chunk: Chunk) {
    if (this.runners.has(chunk.chunkId)) {
      return this.runners.get(chunk.chunkId)!;
    }
    const runtime = this.quickJs.newRuntime();
    const context = runtime.newContext();
    const runner: JsRunner = { runtime, context, chunk };
    this.runners.set(chunk.chunkId, runner);
    return runner;
  }

  public disposeRunner(runner: JsRunner) {
    runner.context.dispose();
    runner.runtime.dispose();
    this.runners.delete(runner.chunk.chunkId);
  }

  public async runScript(runner: JsRunner, script: string): Promise<any> {
    const { context, chunk } = runner;
    try {
      script = GeneralHelpers.replaceGlobals(
        script,
        this.projectVariablesService.variableList
      );
      const result = await context.evalCodeAsync(script);
      if (result.error) {
        throw result.error;
      }
      this.notebookVariablesService.addVarsFromRawToList(
        context.getProp(context.global, 'data').consume(context.dump),
        chunk
      );
      return TruncationHelper.truncateOutput(context.dump(result.value));
    } catch (error: any) {
      throw {
        message: error.message,
        stack: error.stack,
        quickJSError: error,
      };
    }
  }
}
