import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { ProjectService } from '../../services/project/project.service';
import { ChunkTypeService } from '../../services/chunk-type/chunk-type.service';
import { Router } from '@angular/router';
import { ProjectCreate } from '../../interfaces/project.interface';
import { AppStateService } from '../../services/app-state/app-state.service';

@Component({
  selector: 'app-add-project-dialog',
  templateUrl: './add-project-dialog.component.html',
  styleUrls: ['./add-project-dialog.component.scss'],
})
export class AddProjectDialogComponent implements OnInit {
  public chunkTypeList: any[] = [];

  projectForm = this._formBuilder.group({
    name: ['', Validators.required],
    description: ['', Validators.required],
    notebookName: ['', Validators.required],
    chunkName: ['', Validators.required],
    chunkType: ['', Validators.required],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    private projectService: ProjectService,
    private chunkTypeService: ChunkTypeService,
    private dialogRef: MatDialogRef<AddProjectDialogComponent>,
    private router: Router,
    private appStateService: AppStateService
  ) { }

  ngOnInit() {
    this.chunkTypeService.chunkTypes$.subscribe((types: any) => {
      this.chunkTypeList = types;
    });
  }

  public submitForm(formValue: any, isValid: boolean, $event: Event) {
    $event.preventDefault();

    if (!isValid) {
      return;
    }

    const projectData: ProjectCreate = {
      name: formValue.name,
      description: formValue.description,
      notebookName: formValue.notebookName,
      chunkName: formValue.chunkName,
      chunkTypeId: formValue.chunkType,
    };

    this.projectService.create(projectData).subscribe((res: any) => {
      this.dialogRef.close();
      this.appStateService.loadProjects().then(() => {
        this.appStateService.navigateToProject(res.projectId, res.currentVersionId);
      });
    });
  }
}
