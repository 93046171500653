// src/app/helpers/truncation.helper.ts

export class TruncationHelper {
    private static MAX_OUTPUT_LENGTH = 3000; // Adjust this value as needed
    private static TRUNCATION_MESSAGE = '... (output truncated)';
  
    public static truncateOutput(output: any, maxLength: number = TruncationHelper.MAX_OUTPUT_LENGTH): any {
      if (typeof output === 'object' && output !== null) {
        if ('value' in output && 'resultStatus' in output) {
          // Handle the specific structure of the output
          return {
            value: TruncationHelper.truncateString(output.value, maxLength),
            resultStatus: output.resultStatus
          };
        } else {
          return TruncationHelper.truncateObject(output, maxLength);
        }
      } else if (typeof output === 'string') {
        return TruncationHelper.truncateString(output, maxLength);
      } else if (Array.isArray(output)) {
        return TruncationHelper.truncateArray(output, maxLength);
      }
  
      return output;
    }
  
    private static truncateString(str: string, maxLength: number): string {
      if (str.length <= maxLength) {
        return str;
      }
  
      // Check if the string is a comma-separated list of numbers
      if (/^[\d,\s]+$/.test(str)) {
        const numbers = str.split(',').map(n => n.trim());
        let truncatedNumbers = [];
        let currentLength = 0;
  
        for (const num of numbers) {
          if (currentLength + num.length + 1 > maxLength) {
            break;
          }
          truncatedNumbers.push(num);
          currentLength += num.length + 1; // +1 for the comma
        }
  
        return truncatedNumbers.join(', ') + TruncationHelper.TRUNCATION_MESSAGE;
      }
  
      // For other types of strings
      return str.substring(0, maxLength) + TruncationHelper.TRUNCATION_MESSAGE;
    }
  
    private static truncateArray(arr: any[], maxLength: number): any[] {
      let totalLength = 0;
      const truncatedArray = [];
  
      for (const item of arr) {
        const truncatedItem = TruncationHelper.truncateOutput(item, maxLength);
        const itemLength = JSON.stringify(truncatedItem).length;
  
        if (totalLength + itemLength > maxLength) {
          truncatedArray.push('... (array truncated)');
          break;
        }
  
        truncatedArray.push(truncatedItem);
        totalLength += itemLength;
      }
  
      return truncatedArray;
    }
  
    private static truncateObject(obj: any, maxLength: number): any {
      let totalLength = 0;
      const truncatedObject: any = {};
  
      for (const [key, value] of Object.entries(obj)) {
        const truncatedValue = TruncationHelper.truncateOutput(value, maxLength);
        const entryLength = key.length + JSON.stringify(truncatedValue).length;
  
        if (totalLength + entryLength > maxLength) {
          truncatedObject['...'] = '(object truncated)';
          break;
        }
  
        truncatedObject[key] = truncatedValue;
        totalLength += entryLength;
      }
  
      return truncatedObject;
    }
  }