import { Injectable } from '@angular/core';
import { Snapshot } from '../../interfaces/snapshot.interface';
import { NotebookFile } from '../../interfaces/file.interface';
import { GeneralHelpers } from '../../helpers/general.helper';
import { LocalstorageHelper } from '../../helpers/localstorage.helper';
import { CacheService } from '../../services/cache/cache.service';
import { SnapshotService } from '../../services/snapshot/snapshot.service';
import { firstValueFrom } from 'rxjs';
import { AppStateService } from '../../services/app-state/app-state.service';

@Injectable({
  providedIn: 'root'
})
export class NotebookFilesService {
  #appState = new AppStateService();

  constructor(
    private cacheService: CacheService,
    private snapshotService: SnapshotService,
  ) { }

  public async getNotebookFiles() {
    const snapshotId = this.snapshotService.getSnapshotIdFromUrl();
    let files: NotebookFile[] | undefined;

    if (snapshotId) {
      files = await this.getNotebookFilesFromSnapshot(snapshotId);
    } else {
      files = await this.getNotebookFilesFromCache();
    }

    return files ?? [];
  }

  private async getNotebookFilesFromSnapshot(
    snapshotId: number | null = this.snapshotService.getSnapshotIdFromUrl()
  ) {
    if (!snapshotId || snapshotId === 0) {
      return;
    }

    let data: any;

    try {
      data = await firstValueFrom(this.snapshotService.getSnapshot(snapshotId));
    } catch (error) {
      console.log('Error getNotebookFilesFromSnapshot:', error);
    }

    return this.processFiles(data ?? []);
  }

  private async getNotebookFilesFromCache() {
    let data: any;

    try {
      data = await firstValueFrom(this.cacheService.getCacheContent());
    } catch (error) {
      if (!(error instanceof Error && error.name === 'EmptyError')) {
        console.log('Error getNotebookFilesFromCache:', error);
      }
    }

    return this.processFiles(data ?? []);
  }

  public async getSnapshotFileById(snapshotId: number, fileId: string, responseType: string) {
    let data: any;

    try {
      data = await firstValueFrom(this.snapshotService.getSnapshotFile(snapshotId, fileId, responseType as any));
    } catch (error) {
      console.log('Error getSnapshotFileById:', error);
    }

    return data;
  }

  private processFiles(files: NotebookFile[] | any) {
    if (Array.isArray(files) === false && files.files !== undefined) {
      files = files.files;
    }
    return files.map((file: NotebookFile) => {
      const fileType = this.setFileType(file);
      return {
        ...file,
        fileType,
      };
    });
  }

  private setFileType(file: NotebookFile) {
    const fileExtension = GeneralHelpers.fileExtensionFromString(file.name);
    const fileType = GeneralHelpers.getFileType(fileExtension);
    return fileType;
  }
}
