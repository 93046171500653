export interface WSMessage {
    message: string;
    error: WSMessageError;
}

export interface WSMessageError {
    description: string;
    error: string;
    trans_id: string;
}

export enum EnMessage {
    Connected = "Connected",
    ConnectionFailed = "ConnectionFailed",
    ConnectedToFlightServer = "ConnectedToFlightServer",
    DisconnectedFromFlightServer = "DisconnectedFromFlightServer",
    Disconnected = "Disconnected",
    FlightList = "FlightList",
    GotAlias = "GotAlias",
    GotAliases = "GotAliases",
    AliasCreated = "AliasCreated",
    Error = "Error",
    FlightError = "FlightError",
    DisplayOutput = "DisplayOutput",
    ArrowTable = "ArrowTable",
    GetInfo = "GetInfo",
    ActionComplete = "ActionComplete",
}
