import { Injectable } from '@angular/core';
// Chunk Interface
import { Chunk } from '../../interfaces/chunk/chunk.interface';
// Parse csv
import { Papa } from 'ngx-papaparse';
// Jspdf
import { jsPDF } from 'jspdf';
// Services

import { GeneralHelpers } from '../../helpers/general.helper';
import {
  FILE_EXTENSIONS,
  NOTEBOOK_FILE,
  PROJECT_FILE,
  ResponseType,
} from '../../constants/general.constants';

import { LocalforageService } from '../localforage/localforage.service';
import { tableFromIPC, tableToIPC } from 'apache-arrow';

@Injectable({
  providedIn: 'root',
})
export class FileDataService {
  constructor(
    private papa: Papa,
    private localforageService: LocalforageService
  ) {}

  public prepareFileData(
    fileName: string,
    fileExtension: string,
    fileContent: any,
    chunkData?: Chunk
  ) {
    const data = this.getFileData(fileExtension, fileContent);
    const type = this.getFileMimeType(fileExtension, fileContent);
    const name = this.getFileName(fileName, fileExtension, chunkData);
    const file = new File([data], name, {
      type: type,
      lastModified: Date.now(),
    });
    const formData = new FormData();
    formData.append('payload', file);
    return formData;
  }

  private getFileData(fileExtension: string, fileContent: any) {
    switch (fileExtension) {
      case 'csv':
        return this.papa.unparse(fileContent.data || fileContent);
      case 'json':
      case 'js':
        return GeneralHelpers.jsonStringify(fileContent.data || fileContent);
      case 'pdf':
        return this.preparePdfData(fileContent);
      case 'jpg':
      case 'jpeg':
      case 'png':
        return this.processBase64Image(fileContent.data || fileContent);
      case 'arrow':
      case 'parquet':
        return this.processBinaryData(fileContent);
      default:
        return fileContent.data || fileContent;
    }
  }

  private processBinaryData(fileContent: string) {
    if (
      typeof fileContent === 'string' &&
      fileContent !== '' &&
      GeneralHelpers.canBeParsedToNumberArray(fileContent)
    ) {
      return GeneralHelpers.stringToUint8Array(fileContent);
    }
    return fileContent;
  }

  private processBase64Image(base64Image: string) {
    if (!GeneralHelpers.isBase64(base64Image)) {
      return base64Image;
    }

    return GeneralHelpers.base64ToBlob(base64Image);
  }

  private getFileMimeType(fileExtension: string, fileContent: any) {
    const mimeTypes: any = {
      jpg: 'image/jpg',
      jpeg: 'image/jpeg',
      png: 'image/png',
      pdf: 'application/pdf',
      csv: 'text/csv',
      json: 'application/json',
      js: 'application/javascript',
      wasm: 'application/wasm',
      parquet: 'application/parquet',
      arrow: 'application/arrow',
    };
    return mimeTypes[fileExtension] || fileContent.type || 'text/plain';
  }

  private getFileName(
    fileName: string,
    fileExtension: string,
    chunkData?: Chunk
  ) {
    return fileName
      ? `${fileName}.${fileExtension}`
      : `${chunkData?.name}.${fileExtension}`;
  }

  private preparePdfData(fileContent: any) {
    const doc = new jsPDF();
    doc.addImage(
      new Uint8Array(fileContent.data || fileContent),
      'PNG',
      25,
      25,
      150,
      150
    );
    return doc.output('arraybuffer');
  }

  /**
   * Gets the response type based on the file extension.
   * @param fileExtension - The file extension.
   * @returns The response type as a string.
   */
  public getResponseType(fileExtension: string): string {
    let responseType: any = ResponseType.Text;

    if (
      fileExtension === FILE_EXTENSIONS.parquet ||
      fileExtension === FILE_EXTENSIONS.arrow ||
      fileExtension === FILE_EXTENSIONS.wasm ||
      fileExtension === FILE_EXTENSIONS.mp4 ||
      fileExtension === FILE_EXTENSIONS.webm ||
      fileExtension === FILE_EXTENSIONS.ogg ||
      fileExtension === FILE_EXTENSIONS.ogv
    ) {
      responseType = ResponseType.ArrayBuffer;
    }

    if (
      fileExtension === FILE_EXTENSIONS.jpg ||
      fileExtension === FILE_EXTENSIONS.jpeg ||
      fileExtension === FILE_EXTENSIONS.png
    ) {
      responseType = ResponseType.Blob;
    }

    return responseType;
  }

  /**
   * Retrieves the file content based on the file extension and additional file data.
   * @param file - The file content.
   * @param additionalFileData - The additional file data.
   * @param responseType - The response type.
   * @returns The retrieved file content.
   */
  public async getFileCallback(
    file: any,
    additionalFileData?: any,
    responseType?: string
  ) {
    const fileExtension =
      typeof additionalFileData === 'string'
        ? additionalFileData
        : GeneralHelpers.fileExtensionFromString(additionalFileData?.name);

    if (fileExtension === 'csv') {
      file = await this.papa.parse(file).data;
      file = GeneralHelpers.convertPapaparseToArquero(file);
      return file;
    }

    if (fileExtension === 'json') {
      file = GeneralHelpers.jsonParse(file);
      return file;
    }

    if (fileExtension === 'wasm') {
      file = GeneralHelpers.arrayBufferToBase64(file);
      return file;
    }

    if (['png', 'jpg', 'jpeg'].includes(fileExtension as string)) {
      if (
        typeof additionalFileData === 'object' &&
        !GeneralHelpers.detectEmptyObject(additionalFileData)
      ) {
        const fileType = GeneralHelpers.getFileType(fileExtension);
        return GeneralHelpers.blobToBase64(file).then((data: any) => {
          return `${GeneralHelpers.getMimeType(
            fileType,
            GeneralHelpers.fileExtensionFromString(additionalFileData.name)
          )}${data}`;
        });
      }

      return file;
    }

    if (fileExtension === 'arrow') {
      const table = tableFromIPC(file);
      const arrayBuffer: Uint8Array = tableToIPC(table, 'file');
      return arrayBuffer;
    }

    if (responseType && responseType === ResponseType.ArrayBuffer) {
      return new Uint8Array(file);
    }

    if (responseType && responseType === ResponseType.Blob) {
      const arrayBuffer = await new Response(file).arrayBuffer();
      return new Uint8Array(arrayBuffer);
    }

    return file;
  }

  /**
   * Retrieves the file content based on the file extension and additional file data.
   * @param file - The file content.
   * @returns The retrieved file content.
   */
  public async saveFileToIndexDB(
    id: string | any,
    fileData: any,
    level: typeof PROJECT_FILE | typeof NOTEBOOK_FILE = NOTEBOOK_FILE
  ) {
    if (!id) {
      return;
    }
    const key = `${id}-${level}`;
    const existingFileData = await this.localforageService.getItem(key);
    if (existingFileData) {
      return;
    }
    await this.localforageService.setItem(key, fileData);
  }

  /**
   * Retrieves the file content based on the file extension and additional file data.
   * @param file - The file content.
   * @returns The retrieved file content.
   */
  public async getFileFromIndexDB(
    id: string | any,
    level: typeof PROJECT_FILE | typeof NOTEBOOK_FILE = NOTEBOOK_FILE
  ) {
    if (!id) {
      return;
    }
    const key = `${id}-${level}`;
    const existingFileData = await this.localforageService.getItem(key);
    if (existingFileData) {
      return existingFileData;
    }
    return null;
  }

  /**
   * Retrieves the file content based on the file extension and additional file data.
   * @param file - The file content.
   * @returns The retrieved file content.
   */
  public async deleteFileFromIndexDB(
    id: string | any,
    level: typeof PROJECT_FILE | typeof NOTEBOOK_FILE = NOTEBOOK_FILE
  ) {
    if (!id) {
      return;
    }
    const key = `${id}-${level}`;
    const existingFileData = await this.localforageService.getItem(key);
    if (existingFileData) {
      await this.localforageService.removeItem(key);
    }
  }

  public async checkKeyExists(
    id: string | any,
    level: typeof PROJECT_FILE | typeof NOTEBOOK_FILE = NOTEBOOK_FILE
  ): Promise<boolean> {
    const key = `${id}-${level}`;

    try {
      const keys = await this.localforageService.keys();
      if (keys.includes(key)) {
        return true;
      }

      return false;
    } catch (error) {
      console.log('checkKeyExists error: ', error);
      return false;
    }
  }
}
