import { Injectable } from '@angular/core';
import { FileService } from '../file/file.service';
import { CacheService } from '../cache/cache.service';
import { FileDataService } from '../file-data/file-data.service';
import { GeneralHelpers } from '../../helpers/general.helper';
import {
  FILE_EXTENSIONS,
  FILE_TYPE,
  ResponseType,
} from '../../constants/general.constants';
import { firstValueFrom } from 'rxjs';
import { NotebookFilesService } from '../notebook-files/notebook-files.service';
import { ExecutionContext } from '../../interfaces/chunk/chunk-context.interface';
import { AppStateService } from '../app-state/app-state.service';
import { SnapshotService } from '../snapshot/snapshot.service';

@Injectable({
  providedIn: 'root',
})
export class FileUnifiedService {
  constructor(
    private fileService: FileService,
    private cacheService: CacheService,
    private fileDataService: FileDataService,
    private notebookFilesService: NotebookFilesService,
    private snapshotService: SnapshotService
  ) { }

  #appState = new AppStateService();

  private fileTypeProcessingMap = new Map<FILE_TYPE, string>([
    [FILE_TYPE.video, 'bytes'],
    [FILE_TYPE.audio, 'bytes'],
    [FILE_TYPE.image, 'bytes'],
    [FILE_TYPE.parquet, 'bytes'],
    [FILE_TYPE.csv, 'default'],
    [FILE_TYPE.json, 'default'],
    [FILE_TYPE.arrow, 'default'],
    [FILE_TYPE.text, 'default'],
    [FILE_TYPE.javascript, 'default'],
    [FILE_TYPE.python, 'default'],
    [FILE_TYPE.md, 'default'],
    [FILE_TYPE.wasm, 'bytes'],
    [FILE_TYPE.whl, 'bytes'],
    [FILE_TYPE.blob, 'bytes'],
    [FILE_TYPE.binary, 'bytes'],
    [FILE_TYPE.unknown, 'default'],
  ]);

  private getFileProcessingMethod(fileType: FILE_TYPE): string {
    return this.fileTypeProcessingMap.get(fileType) || 'default';
  }

  private async getFileContent(
    file: any,
    fileName: string,
    responseType: string,
    fileType: FILE_TYPE
  ): Promise<any> {
    if (!file) {
      return null;
    }

    const processingMethod = this.getFileProcessingMethod(fileType);

    if (processingMethod === 'bytes') {
      switch (responseType) {
        case ResponseType.ArrayBuffer:
          return new Uint8Array(file);
        case ResponseType.Text:
        case ResponseType.Blob:
          const textArrayBuffer = await new Response(file).arrayBuffer();
          return new Uint8Array(textArrayBuffer);
        default:
          return file;
      }
    }

    const fileExtension = GeneralHelpers.fileExtensionFromString(fileName);

    if (
      fileExtension === FILE_EXTENSIONS.csv ||
      fileExtension === FILE_EXTENSIONS.arrow
    ) {
      return await this.fileDataService.getFileCallback(file, fileExtension);
    }

    return file;
  }

  public async getFile(
    args: any[],
    chunkContext?: ExecutionContext
  ): Promise<any> {
    if (chunkContext) {
      chunkContext.setBusy(true, 'Fetching file');
    }

    try {
      if (!args.length || typeof args[0] !== 'string' || !args[0].trim()) {
        if (chunkContext) {
          chunkContext.addMessage('Invalid or missing file name.', 'danger');
        }

        chunkContext?.setBusy(false);

        return null;
      }

      const fileName = args[0];
      const fileExtension = GeneralHelpers.fileExtensionFromString(fileName);
      const responseType = this.fileDataService.getResponseType(fileExtension);
      const fileType = GeneralHelpers.getFileType(fileExtension);

      // Get project files
      const projectFiles: any[] = await firstValueFrom(this.fileService.get());
      const projectFile = GeneralHelpers.findElementInArrayByProp(
        projectFiles,
        'name',
        fileName
      );

      if (projectFile) {
        const fileId = projectFile.fsitemId;
        const file = await firstValueFrom(
          this.fileService.getById(fileId, responseType as any)
        );

        return await this.getFileContent(
          file,
          fileName,
          responseType,
          fileType as FILE_TYPE
        );
      }

      const notebookFiles: any[] = await firstValueFrom(
        this.cacheService.getCacheContent()
      );
      const notebookFile = GeneralHelpers.findElementInArrayByProp(
        notebookFiles,
        'name',
        fileName
      );
      if (notebookFile) {
        const fileId = notebookFile.fileId;
        const file = await firstValueFrom(
          this.cacheService.getCacheContentFile(fileId, responseType as any)
        );

        return await this.getFileContent(
          file,
          fileName,
          responseType,
          fileType as FILE_TYPE
        );
      }

      const snapshotFiles: any[] =
        await this.notebookFilesService.getNotebookFiles();

      const snapshotFile = GeneralHelpers.findElementInArrayByProp(
        snapshotFiles,
        'name',
        fileName
      );

      if (snapshotFile) {
        const snapshotId = this.snapshotService.getSnapshotIdFromUrl();
        const fileId = snapshotFile.fileId;
        const file = await this.notebookFilesService.getSnapshotFileById(
          snapshotId as number,
          fileId,
          responseType as any
        );

        return await this.getFileContent(
          file,
          fileName,
          responseType,
          fileType as FILE_TYPE
        );
      }
    } catch (error) {
      if (chunkContext) {
        chunkContext.addMessage(`Error fetching file: ${error}`, 'danger');
      }

      return null;
    } finally {
      if (chunkContext) {
        chunkContext.setBusy(false);
      }
    }
  }

  public async imageProcessing(
    args: any[],
    chunkContext: ExecutionContext
  ): Promise<any> {
    if (typeof (window as any).Jimp === 'undefined') {
      await this.runScript();
    }

    if (
      !args.length ||
      typeof args[0] !== 'string' ||
      !args[0].trim() ||
      typeof args[1] !== 'string' ||
      !args[1].trim()
    ) {
      chunkContext.addMessage(
        'Missing or invalid arguments for image processing.',
        'danger'
      );
      return;
    }

    const fileName = args[0].trim();
    const method = args[1].trim();
    const param = args.slice(2);

    const file: any = await this.getFile([fileName], chunkContext);
    if (!file) {
      chunkContext.addMessage(
        'File not found or cannot be loaded for image processing.',
        'danger'
      );
      return;
    }
    const buffer = await GeneralHelpers.blobToBuffer(file);
    try {
      const image = await (window as any).Jimp.read(buffer);
      let processed;

      if (param) {
        processed = await image[method](...param);
      } else {
        processed = await image[method]();
      }
      const result = await processed.getBase64Async((window as any).Jimp.AUTO);
      return result;
    } catch (error) {
      console.error(error);
    }
  }

  public async runScript(): Promise<any> {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.src = 'jimp.min.js';
    document.getElementsByTagName('head')[0].appendChild(script);
    return new Promise<void>((resolve, reject) => {
      script.onload = () => {
        resolve();
      };
    });
  }
}
